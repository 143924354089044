import React from "react"
import { Routes, Route, HashRouter as Router, Link } from 'react-router-dom';
import "./App.css"
import MintPage from "./MintPage";


function App(){
    return (
        <MintPage></MintPage>
    )
}
export default App